export const checkOutEmployeeValidation = [
  {
    field: "employee_checkin_time",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler stempel ind tidspunkt"
  },
  {
    field: "employee_checkout_time",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler stempel ud tidspunkt"
  },
  {
    field: "cpr_nr",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler CPR nr."
  },
  {
    field: "cpr_nr",
    method: "isLength",
    args: [{ min: 10, max: 10 }],
    validWhen: true,
    message: "CPR nr. skal være 10 cifre"
  },
  {
    field: "bank_account_nr",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler konto nr."
  },
  {
    field: "bank_account_nr",
    method: "isLength",
    args: [{ min: 10, max: 10 }],
    validWhen: true,
    message: "Konto nr. skal være 10 cifre"
  },
  {
    field: "bank_reg_nr",
    method: "isEmpty",
    validWhen: false,
    message: "Mangler reg. nr."
  },
  {
    field: "bank_reg_nr",
    method: "isLength",
    args: [{ min: 4, max: 4 }],
    validWhen: true,
    message: "Reg. nr. skal være 4 cifre"
  },
  {
    field: "tax_card_type",
    method: "isIn",
    args: [['1', '2']],
    validWhen: true,
    message: "Mangler SKATTEKORT"
  }
];
