// Modules
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import { bindActionCreators } from "redux";

// Styles
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { disableTakeOverProfile, signOut } from "../actions/authActions";
import jobButlerIcon from "../static/jobButlerIcon.png";

// Actions
import colors from "../styles/colors";
import { showModal } from "../actions/uiActions"; 

// Components
import CorporationContractModal from "./corporation/CorporationContractModal";
import EmployeeContractModal from "./employee/EmployeeContractModal";

const NavigationBar = props => {
  const history = useHistory();
  const [showProfile, setShowProfile] = useState(false);

  const {
    isAuthenticatedCorporation,
    isAuthenticatedEmployee,
    takenOverEmployee,
    takenOverCorporation,
    user
  } = useSelector(state => state.auth);

  const { showContract = true } = props;

  return (
    <ul className={componentStyle(colors)}>
      {showProfile && (
        <button
          onClick={() => setShowProfile(false)}
          className="hide-container"
        />
      )}

      <li onClick={() => history.push("/")} className="company-icon-container">
        <img
          className="tjener-teamet-icon"
          src={jobButlerIcon}
          alt={"jobbutler-icon"}
        />

        {/** ADMIN LOGIN **/}
        {user.is_admin && (
          <div className="admin-title">
            <p>Admin</p>
          </div>
        )}

        {/** TAKEN OVER ROLE **/}
        {(takenOverEmployee === true || takenOverCorporation === true) && (
          <button
            onClick={() => props.disableTakeOverProfile()}
            className="taken-over-btn"
          >
            Gå tilbage til admin
          </button>
        )}
      </li>

      <li className="right-icon-container">
        <a href="tel:+4530488484" style={{marginRight: '25px'}}>Support: +45 3048 8484</a>

        {/** IMAGE ICON **/}
        <div className="right-icon">
          <button
            onClick={() => {
              setShowProfile(true);
            }}
          >
            {user.profile_image && user.profile_image && user.baseURL_image && (
              <div className="user-image-container">
                <img
                  src={`${user.baseURL_image}w_1000,h_1000,c_fill,g_faces/${user.profile_image}`}
                  alt={"navbar"}
                />
              </div>
            )}
            {!user.profile_image && (
              <div className="burger-icon-container">
                <DotsVerticalIcon size={40} />
              </div>
            )}
          </button>
        </div>

        {showProfile && (
          <Fragment>
            {/** Corporation new user **/}
            {!isAuthenticatedCorporation && !isAuthenticatedEmployee && (
              <div className="box-container">
                <p
                  onClick={() => {
                    history.push("/corporationLogin");
                  }}
                >
                  For virksomhed
                </p>
                <div />
                <p
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Opret bestilling
                </p>
                <div />
                <p
                  onClick={() => {
                    history.push("/employeeLogin");
                  }}
                >
                  Søger job
                </p>
                <div />
                <p
                  onClick={() =>
                    window.open(
                      "https://www.tjenerteamet.dk/faq.html",
                      "_blank"
                    )
                  }
                >
                  FAQ
                </p>
              </div>
            )}

            {/** Corporation admin **/}
            {user.is_admin && isAuthenticatedCorporation && (
              <div className="box-container">
                <p onClick={() => history.push("/admin/showEmployees")}>
                  <span>ADMIN</span> - Vis ansatte
                </p>
                <p onClick={() => history.push("/admin/showCorporations")}>
                  <span>ADMIN</span> - Vis virksomheder
                </p>
                <p onClick={() => history.push("/admin/upcomingBookings")}>
                  <span>ADMIN</span> - Bookinger (Fremtidige)
                </p>
                <p onClick={() => history.push("/admin/oldBookings")}>
                  <span>ADMIN</span> - Bookinger (Historiske)
                </p>
                <p
                  onClick={() =>
                    history.push("/admin/approve-booking-overview")
                  }
                >
                  <span>ADMIN</span> - Godkend bookings
                </p>
                <p onClick={() => history.push("/admin/staffType")}>
                  <span>ADMIN</span> - Indstillinger
                </p>
                <p onClick={() => history.push("/editcorporation")}>
                  Rediger profil
                </p>
                <div />
                <p
                  onClick={() =>
                    props.showModal({
                      content: <CorporationContractModal />
                    })
                  }
                >
                  Kontrakt
                </p>
                <div />
                <p
                  onClick={() => {
                    props.signOut();
                    history.push("/corporationLogin");
                  }}
                >
                  Log ud
                </p>
              </div>
            )}

            {/** Corporation **/}
            {!user.is_admin && isAuthenticatedCorporation && (
              <div className="box-container">
                <p onClick={() => history.push("/newBooking")}>
                  Opret bestilling
                </p>
                <div />
                <p onClick={() => history.push("/editcorporation")}>
                  Rediger profil
                </p>
                <div />
                <p
                  onClick={() =>
                    props.showModal({
                      content: <CorporationContractModal />
                    })
                  }
                >
                  Kontrakt
                </p>
                <div />
                <p
                  onClick={() => {
                    props.signOut();
                    history.push("/corporationLogin");
                  }}
                >
                  Log ud
                </p>
              </div>
            )}

            {/** Employee **/}
            {isAuthenticatedEmployee && (
              <div className="box-container">
                {!user.new_user && (
                  <p onClick={() => history.push("/editemployee")}>
                    Rediger profil
                  </p>
                )}
                {showContract && (
                  <Fragment>
                    <div />
                    <p
                      onClick={() =>
                        props.showModal({
                          content: <EmployeeContractModal />
                        })
                      }
                    >
                      Kontrakt
                    </p>
                  </Fragment>
                )}
                <div />
                <p
                  onClick={() =>
                    window.open(
                      "https://www.tjenerteamet.dk/faq-app.html",
                      "_blank"
                    )
                  }
                >
                  FAQ
                </p>
                <div />
                <p
                  onClick={() => {
                    props.signOut();
                    history.push("/employeeLogin");
                  }}
                >
                  Log ud
                </p>
              </div>
            )}
          </Fragment>
        )}
      </li>
    </ul>
  );
};

const componentStyle = colors => css`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.8rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: ${colors.white};
  z-index: 10;
  justify-content: space-between;

  .burger-icon-container {
    svg {
      padding: 0.25rem;
    }

    svg:hover {
      background-color: ${colors.lightGrey};
      border-radius: 50%;
      padding: 0.25rem;
    }
  }

  .tjener-teamet-icon {
    height: 3rem;
    width: 3rem;
    margin-left: 2rem;
  }

  .company-icon-container {
    cursor: pointer;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .taken-over-btn {
      font-size: 0.75rem;
      padding: 0.25rem 1.25rem;
      background-color: ${colors.darkRed};
      margin-right: 1.25rem;
      margin-left: 0.5rem;
      color: ${colors.white};
      border-radius: 0.25rem;
      font-weight: 600;
      outline: none;
    }

    .taken-over-btn:hover {
      background-color: ${colors.red};
    }

    .company-title {
      margin-left: 0.5rem;
      font-size: 1.5rem;
      font-weight: 400;
      margin-right: 0.5rem;
    }
    .admin-title {
      padding: 0.25rem 0.75rem;
      background-color: ${colors.darkerGrey};
      border-radius: 0.25rem;

      p {
        color: ${colors.white};
        font-weight: 500;
      }
    }
  }

  .right-icon-container {
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .right-icon {
      position: relative;
      right: 0;
      display: flex;
      align-items: center;

      button {
        color: ${colors.black};
        padding: 0.5rem;
        border-radius: 0.25rem;
        outline: none;
      }
    }

    .user-image-container,
    .no-image-container {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: 2.5rem;
      height: 2.5rem;
      align-items: center;

      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 9999px;
        border: 1px solid #edf2f7;
      }

      img:hover {
        border-color: #a0aec0;
      }
    }

    .no-image-container {
      margin-right: 3rem;

      div {
        display: flex;
        flex-direction: row;

        p {
          color: ${colors.darkerGrey};
          font-weight: 500;
          margin-left: 0.25rem;
        }
        p:hover {
          color: ${colors.black};
        }
      }
    }

    .box-container {
      width: 13rem;
      position: absolute;
      z-index: 10;
      top: 51px;
      background-color: ${colors.lightGrey};
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      border-radius: 0.5rem;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      right: 1rem;

      div {
        margin: 0.25rem 0.5rem 0.5rem;
      }

      p {
        cursor: pointer;
        font-size: 0.75rem;
        display: block;
        padding: 0.5rem 1rem;
        color: ${colors.black};
        font-weight: 500;
        min-width: 10rem;

        span {
          font-weight: 700;
        }
      }

      p:hover {
        background-color: ${colors.darkerGrey};
        color: ${colors.white};
      }
    }
  }

  .hide-container {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: default;
  }
`;

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch),
  signOut: bindActionCreators(signOut, dispatch),
  disableTakeOverProfile: bindActionCreators(disableTakeOverProfile, dispatch)
});

export default connect(null, mapDispatchToProps)(NavigationBar);
